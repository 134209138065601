import * as types from '../../configs/constants/actionType'
import generateActions from '../actions/actions'
import { ROLES_DESCRIPTION } from '../../configs/constants/roles.const'
import { parseUserInfo } from '../../utils/helper'

const loginReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.LOGIN).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoginLoading: false })
    case generateActions(types.LOGIN).FAILURE:
      if ([401, 403, 500].includes(action.error.status)) {
        localStorage.removeItem('user')
        localStorage.removeItem('userInfo')
        window.location.reload()
      }
      return Object.assign({}, state, { isLoginLoading: false })
    case generateActions(types.LOGIN).REQUEST:
      return Object.assign({}, state, { isLoginLoading: true })
    case types.CLEAR_USER_INFO:
      localStorage.removeItem('user')
      localStorage.removeItem('userInfo')
      window.location.reload()
      return {}
    case types.UPDATE_USER:
      const currentUserInfo = localStorage.getItem('userInfo') ? parseUserInfo() : ''
      const newUserInfo = Object.assign(
        {},
        {
          ...currentUserInfo,
          role: action.updatedRole,
          roleDescription: ROLES_DESCRIPTION[action.updatedRole],
          status: action.updateStatus
        }
      )
      localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
      return Object.assign({}, state, { userInfo: { ...newUserInfo } })
    default:
      return state
  }
}

export default loginReducer
